import(/* webpackMode: "eager" */ "/Users/pongki/Documents/sophonic/gemini-chatbot/components/chat.tsx");
import(/* webpackMode: "eager" */ "/Users/pongki/Documents/sophonic/gemini-chatbot/components/flights/boarding-pass.tsx");
import(/* webpackMode: "eager" */ "/Users/pongki/Documents/sophonic/gemini-chatbot/components/flights/destinations.tsx");
import(/* webpackMode: "eager" */ "/Users/pongki/Documents/sophonic/gemini-chatbot/components/flights/list-flights.tsx");
import(/* webpackMode: "eager" */ "/Users/pongki/Documents/sophonic/gemini-chatbot/components/flights/purchase-ticket.tsx");
import(/* webpackMode: "eager" */ "/Users/pongki/Documents/sophonic/gemini-chatbot/components/flights/select-seats.tsx");
import(/* webpackMode: "eager" */ "/Users/pongki/Documents/sophonic/gemini-chatbot/components/hotels/list-hotels.tsx");
import(/* webpackMode: "eager" */ "/Users/pongki/Documents/sophonic/gemini-chatbot/components/stocks/index.tsx");
import(/* webpackMode: "eager" */ "/Users/pongki/Documents/sophonic/gemini-chatbot/components/stocks/message.tsx");
import(/* webpackMode: "eager" */ "/Users/pongki/Documents/sophonic/gemini-chatbot/components/ui/icons.tsx");
import(/* webpackMode: "eager" */ "/Users/pongki/Documents/sophonic/gemini-chatbot/node_modules/ai/rsc/dist/rsc-shared.mjs")