import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getAnalytics, isSupported,Analytics } from 'firebase/analytics'; 

const firebaseConfig = {
  apiKey: "AIzaSyCmdQWqga13ckLKKwbyMU56HUcrtvgLuFs",
  authDomain: "chatpdf-test.firebaseapp.com",
  projectId: "chatpdf-test",
  storageBucket: "chatpdf-test.appspot.com",
  messagingSenderId: "1040082469422",
  appId: "1:1040082469422:web:0d7f08aec60368063c95bf",
  measurementId: "G-JH0QV2Q2PQ"
};


const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
let analytics: Analytics | undefined; 
if (typeof window !== "undefined") {
  isSupported().then(supported => {
    if (supported) {
      analytics= getAnalytics(app);
    }
  }).catch(error => {
    console.error('Analytics not supported:', error);
  });
}

export { app, storage, analytics };
